.Hizmetler {
  position: relative;
  overflow: hidden;
}

.Hizmetler .bannerLogoHzmtlr {
  position: absolute;
  top: -100px;
  right: -50px;
  opacity: 0.2;
}
.Hizmetler .image-group {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: transparent;
  border-radius: 4px;
}
.Hizmetler .full-cover {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 110%;
  object-position: center;
  object-fit: cover;
}

.Hizmetler .baslik1 {
  letter-spacing: -3px;
}
.konteynerText .text1 ul li {
  list-style: initial;
  list-style-type: square;
  font-size: clamp(14px, 1.43vw, 18px);
  text-indent: 0px !important;
  text-align: start !important;
  text-align-last: start !important;
  padding-bottom: 20px;
  color: rgba(255, 255, 255, 0.5);
}
.konteynerText .text1 ul li::marker {
  color: #cfcfcf;
}
.liBaslik {
  color: #111;
  font-weight: 600;
  letter-spacing: 1.2px;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: clamp(16px, 1.43vw, 22px);
}

@media screen and (max-width: 1024px) {
  .Hizmetler .image-group {
    height: 400px;
    border-radius: 7px;
  }
  .Hizmetler .baslik1 {
    letter-spacing: 0px;
  }
  .Hizmetler .full-cover {
    height: 130%;
  }
}

@media screen and (max-width: 768px) {
  .Hizmetler .image-group {
    height: 300px;
  }
}
