.Dot {
  position: relative;
  overflow: hidden;
}

.Dot .DtTanitim {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.Dot h1 {
  font-size: clamp(26px, 3.53vw, 60px);
  font-weight: 400;
  color: var(--darkgreen);
  margin-bottom: 30px;
  line-height: 1.4;
}
.transportBanner {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  filter: drop-shadow(21px 10px 20px #0e0e3bc1);
}
.transportBanner img {
  width: auto;
  height: 600px;
  filter: grayscale(100%) sepia(60%);
  -webkit-mask-image: url("../../../public/img/zoroglu-z.png");
  mask-image: url("../../../public/img/zoroglu-z.png");
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}
.Dot .text1 {
  max-width: 51ch;
}

@media screen and (max-width: 1024px) {
  .Dot .DtTanitim {
    display: grid;
    grid-template-columns: 1fr;
  }
  .transportBanner img {
    display: none;
  }
}
