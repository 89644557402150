.Referance .baslik1{
    margin-bottom: 12px;
    
    font-size: clamp(24px,2.94vw,50px);
    text-align: center;
    
}
.slidelogo .logos{
    object-fit: cover;
    height: auto;
    padding: 10px;
    width: 100%;
    object-fit : scale-down ;
}