.breadCrumps{
    height: 80vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(0,0,1,.18),rgba(0, 0, 0, 0.549)),url('../Assets/konteynerler.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
   
    
  }
  .breadText{
    position: absolute;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-wrap: wrap;
    color: white;
    max-width: 80ch;
    transform: translateY(-40%);
    padding-left: 30px;
    padding-right: 30px;
  }
  .breadTexts{
    display: flex;
    word-break: break-all;
    row-gap: 5px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    transition: all 0.4s ease;
   
    font-size: clamp(13px,1.31vw,20px);
    font-weight: 400;
    letter-spacing: 3.5px;
    color: hsla(0,0%,100%,.5);
    


  }
  .breadTexts a{
    transition: all 0.4s ease;
  }
  .breadTexts a:hover{
    color: #fff;
  }
  .breadSplit{
    content: "";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: 15px;
    width: 2px;
    transform: skew(-15deg);
    background-color:hsla(0,0%,100%,.5) ;
    
    margin-left: 15px;
    margin-right: 15px;
   

  }
.breadCrumps .container{
  overflow: visible;
}
@media screen and (max-width:640px) {
  .breadCrumps .container{
    margin-inline: initial;
  }
}

  @media screen and (max-width:431px) {
    .breadText{
      padding-left: 5px;
      padding-right: 73px;
    }
    .breadText .headerbaslik{
      margin-bottom: 61px;
    }
    .flex-wrap a{
      font-size: 15px;
      
    }
    .height-65{
      height: 60px;
    }
  }