@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap");

/* RESET */

/* scroll settings */
body::-webkit-scrollbar {
  width: 0.5em;
}
/* output */
body::-webkit-scrollbar-thumb {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(107, 107, 107)),
    color-stop(0.72, rgb(84, 84, 84)),
    color-stop(0.86, rgb(39, 39, 39))
  );
}
body::-webkit-scrollbar-thumb:hover {
  opacity: 1;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
}

body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
/* scroll settings */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  user-select: none;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}
span {
  display: inline;
}
a,
img,
time,
span,
input,
button,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: var(--ff-rubik);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--onyx);
  font-size: 1.6rem;
  line-height: 1.7;
}
.flex {
  display: flex;
  align-items: center;
}
.grid {
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}
.gridIcerik {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 10%;
  grid-row-gap: 0px;
  align-items: center;
}
input {
  font-size: 100%;
}
.spanText {
  font-size: 14px;
}

:focus-visible {
  outline-offset: 4px;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--cultured-2);
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  border: 2px solid var(--cultured-2);
}

::-webkit-scrollbar-thumb:hover {
  background-color: hsl(0, 0%, 70%);
}

/* ROOT */

:root {
  --green: #050c9c;
  --darkgreen: #050c9c;
  --lightBlue: #2a4ff6;
  --background: #0a1444;
}
.headerbaslik {
  color: #fff;
  font-size: calc(2.3rem + 2vw);
  line-height: 1.4;
}
.kucukbaslik {
  position: relative;
  font-size: calc(0.2rem + 1vw);
  letter-spacing: -0.01em;
  line-height: 1.3;
  margin-bottom: 5rem;
  color: #45454589;
  transition: background-size cubic-bezier(0.1, 0.5, 0.5, 1) 0.5s;
}
.text1 {
  --tw-text-opacity: 0.9;
  color: rgb(17 17 17 / var(--tw-text-opacity));
  font-size: clamp(14px, 1.41vw, 22px);
  font-weight: 300;
}

.container {
  padding-inline: 15px;
  padding-top: 6rem;
  padding-bottom: 3rem;
  overflow: hidden;
}
.pb30 {
  padding-bottom: 30px;
}
.pb40 {
  padding-bottom: 40px;
}

.bkBaslik {
  color: #fff;
  font-size: clamp(15px, 1.29vw, 21px);
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 3px;
  font-weight: 400px;
  margin-bottom: 30px;
}
.baslik1 {
  font-size: clamp(26px, 3.53vw, 60px);
  font-weight: 400;
  color: var(--darkgreen);
  margin-bottom: 30px;
  line-height: 1.3;
}
.grid2 {
  display: grid;
  gap: 100px;
  grid-template-columns: 1fr 1fr;
}
.bgGreen {
  background-color: var(--background);
  padding-bottom: 6rem;
  padding-top: 4rem;
}
.bgGreen .baslik1 {
  color: rgba(255, 255, 255, 1);
}
.bgGreen .liBaslik {
  color: rgba(255, 255, 255, 0.9);
}
.bgGreen ul li {
  color: rgb(255, 255, 255);
}

@media screen and (max-width: 1024px) {
  .grid2 {
    gap: 70px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media screen and (min-width: 640px) {
  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }
}
@media screen and (max-width: 639px) {
  .container {
    max-width: 500px;
    width: auto;
    margin-inline: auto;
  }
}
@media screen and (max-width: 509px) {
  .container {
    max-width: 430px;
  }
}
@media screen and (max-width: 430px) {
  .container {
    max-width: 370px;
  }
}
@media screen and (max-width: 370px) {
  .container {
    max-width: 330px;
  }
}
@media screen and (min-width: 768px) {
  .container {
    max-width: 708px;
    width: 100%;
    margin-inline: auto;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 932px;
    width: 100%;
    margin-inline: auto;
  }
}

@media screen and (min-width: 1024) {
  .container {
    max-width: 964px;
    width: 100%;
    margin-inline: auto;
  }
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1285px;
    width: 100%;
    margin-inline: auto;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    max-width: 1390px;
    width: 100%;
    margin-inline: auto;
  }
}
@media screen and (max-width: 1024px) {
  .cardBaslik {
    font-size: calc(1.6rem + 1vw);
  }
}
