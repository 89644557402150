.Services {
}
.Services .bkBaslik {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0px;
}
.Services .baslik1 {
  color: #fff;
}
.Services .itemGroup {
  padding-bottom: 60px;
  padding-top: 60px;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  padding-left: 0;
  width: 100%;
  border-width: 0;
  border-left-width: 12px;
  transition: all 0.45s ease-in;
}
.Services .itemGroup:last-child {
  margin-bottom: 30px;
}
.Services .itemGroup .baslik1 {
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-size: clamp(24px, 2.94vw, 50px);
  position: relative;
  z-index: 4;
}
.Services .itemGroup .textField {
  color: #fff;
  max-width: 60ch;
  height: 0;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
}
.Services .itemGroup .textField p {
  visibility: hidden;
  height: 0px;
  transition: all 0.05s ease;
}
.textGroup {
  z-index: 2;
}
.Services .item {
  padding-left: 280px;
}

.Services .itemGroup.active {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
  background-color: hsla(0, 0%, 100%, 0.11);
  z-index: -111;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-style: solid;
  border-width: 0;
  border-left-width: 12px;
}
.Services .itemGroup.active .baslik1 {
  color: #fff;
}

.Services .itemGroup.active .textField {
  height: 120px;
  visibility: visible;
  opacity: 1;
}
.Services .itemGroup.active .textField p {
  visibility: visible;
}

.wrapper {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 7fr) minmax(0, 6fr);
}

.image-conts {
  max-width: 1700px;
  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  right: 0;
  position: absolute;
}
.image-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
}
.image-field {
  object-position: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
}
.image-field img {
  transition: all 0.6s ease;
  opacity: 1;
  object-position: center;
  object-fit: contain;
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
  height: 100%;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
}
.dsNone {
  display: none;
}

@media screen and (max-width: 1024px) {
  .wrapper {
    display: none;
  }
  .dsNone {
    display: block;
  }
  .Services .image-conts {
    z-index: 0;
  }
  .Services .container {
    padding-bottom: 0;
  }
  .Services .itemGroup {
    z-index: 1;
    padding-top: 0;
    padding-bottom: 6rem;
  }
  .Services .itemGroup .baslik1 {
    color: #fff;
  }

  .Services .itemGroup .textField {
    height: 120px;
    visibility: visible;
    opacity: 1;
  }
  .Services .itemGroup .textField p {
    visibility: visible;
  }
  .Services .item {
    padding-left: 0;
  }
  .image-conts {
    display: none;
  }
  .itemGroup .text {
    color: #fff;
    font-size: clamp(13px, 1.41vw, 19px);
  }
}

.yunusMain {
  background: #0a1444;
  overflow: hidden;
  padding-bottom: 3rem;
}

.yunusSection {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
}

article {
  padding: 10rem 1rem 10rem 28rem;
  max-width: 90ch;
  margin-bottom: 10px;
}
.textField {
  color: #fff;
  max-width: 60ch;
}

/* .yunusimg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  max-width: 100%;
  max-height: 500px;
  object-fit: cover;
} */
.yunusimg {
  position: absolute;

  right: 0;
  bottom: 0;
  width: auto;
  max-height: 100%;
  object-fit: cover;
  filter: opacity(0.1);
}

.yunusMain .baslik1 {
  color: #fff;
  z-index: 3;
  position: relative;
}
.yunusMain .bkBaslik {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0px;
  z-index: 3;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .yunusSection {
    display: none;
  }
}
