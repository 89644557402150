
.CounterUp .content{
    position: relative;
    
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.CounterUp .box{
    width: calc(25% - 35px);
    background: rgba( 255, 255, 255, 0.05 );
    
    backdrop-filter: blur( 7.5px );
    -webkit-backdrop-filter: blur( 7.5px );
    border-radius: 10px;
   
    
   
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    
}


.CounterUp .counter{
    margin-bottom: 12px;
    font-weight: 300;
    font-size: clamp(50px,1.29vw,60px);
    color: var(--green);
    display: flex;
    
}
.CounterUp .counterText{
    font-weight: 600;
    font-size: clamp(17px,1.29vw,20px);
    color: rgba(25, 25, 25, 0.615);
}

@media screen and (max-width:1036px) {
    .CounterUp{
        padding: 50px 50px 0 50px ;
    }
    .CounterUp .box{
        width: calc(50% - 30px);
        margin-bottom: 50px;
    }
}
@media screen and (max-width:580px) {
    .CounterUp .box {
        width: 100%;
    }
    .CounterUp .cardBaslik{
        font-size: calc(1.4rem + 1vw);
    }
    .CounterUp .container{
        padding-inline: 0;
    }
}