.footer-contact {
  align-items: center;
  flex-direction: column;
  display: flex;
}
.footer-field {
  position: relative;
  overflow: hidden;
  isolation: isolate;
  background-color: #0a1444;
}
.logoBanner {
  position: absolute;
  bottom: -100px;
  top: 0;
  left: -450px;
  transform: rotate(58deg);
}
.footer-top {
  padding-top: 30px;
}
.wrapper-grid {
  display: grid;
  grid-template-columns: minmax(0, 4fr) minmax(0, 8fr);
  gap: 100px;
  align-items: center;
  margin-bottom: 60px;
}
.wrapper-grid3 {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 150px;
  align-items: top;
  width: 100%;
}
.footerSplit {
  width: 100%;
  height: 1px;
  border-color: hsla(0, 0%, 40%, 0.216);
  border-style: solid;
  border-width: 1px;
}
.logo-field img {
  max-width: 320px;
  object-fit: contain;
  object-position: center;
  height: 100%;

  animation: shadow 2s alternate infinite;
}
@keyframes shadow {
  0% {
    filter: drop-shadow(5px 5px 20px #ffffff39);
  }
  100% {
    filter: drop-shadow(5px 5px 35px #ffffff74);
  }
}

/* contact */

.contact-info {
  margin-bottom: 45px;
  transition: all 0.4s ease-in;
}
footer .title {
  color: hsla(0, 0%, 100%, 0.5);
  font-weight: 400;
  font-size: 22px;
  word-break: break-all;
  transition: all 0.35s ease;
}
footer .text {
  font-weight: 400;
  color: #ffffffc5;
  font-size: 22px;
  word-break: break-all;
  padding-bottom: 50px;
  transition: all 0.35s ease;
}

.contact-info ul li:hover .title {
  color: #ffffffc5;
}
.contact-info ul li:hover .text {
  transform: translateX(1%);
}
/* sociial media */
.social-media-field {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-wrapF {
  display: flex;
  gap: 25px;
  font-size: 22px;
  margin-top: 15px;
}
.flex-wrapF a {
  color: hsla(0, 0%, 100%, 0.5);
  font-size: 22px;
  transition: all 0.4s ease;
}
.flex-wrapF a:hover {
  color: #ffffffc5;
}
.footer-menu-content {
  width: 30ch;
}
.adres {
  width: 23ch;
}
.footer-menu-content a {
  transition: 0.3s ease-in-out;
  --tw-text-opacity: 1;
  color: #ffffffc5;
  line-height: 1.25;
  font-size: 22px;
  word-break: break-all;
  padding-bottom: 25px;
}
.footer-menu-content ul li {
  overflow: hidden;
}

.footer-menu-content a:hover::before {
  display: block;
  transition: 0.3s ease-in-out;
}

.footer-menu-content a::before {
  content: " ";
  width: 10px;
  background-color: #ffffffc5;
  position: absolute;
  left: -15px;
  top: 25%;
  transition: 0.3s ease-in-out;

  height: 2px;
  display: flex;
  align-items: center;
}
.footer-menu-content a:hover {
  transform: translateX(10px);
}
.WorldMap {
  position: absolute;
  z-index: -1;
  opacity: 0.1;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-12%);
  transform: translateX(12%);
  width: auto;

  height: 100%;
}
.WorldMap img {
  max-width: 1200px;
}

/* scroll up */

.up-btn-area {
  overflow: hidden;
  gap: 15px;
  cursor: pointer;
  width: auto;
  margin-left: auto;
  isolation: isolate;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  transform: translateX(-50%);
}
.up-btn {
  transition: all 0.45s ease;
  border-style: solid;
  border-color: hsla(0, 0%, 100%, 0.401);
  border-width: 1px;
  border-radius: 999px;
  width: 63px;
  height: 63px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.up-btn .upIcon {
  color: #ffffffc5;
  display: flex;
  justify-content: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  top: 0;
  transition: all 0.4s ease;
}
.up-btn .upIcon:hover {
  top: -5px;
}

/* footer bottom */

.wrapper-text {
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: space-between;
}
.signature {
  font-weight: 600;
  /* color:hsla(0, 0%, 0%, 0.5); */
  color: #ffffffc5;
}
.wrapper-grid3 .text {
  padding-bottom: 0;
}
.copyrightText {
  color: #ffffffc5;
}

@media screen and (max-width: 1024px) {
  .wrapper-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 57px;
  }
  .wrapper-grid3 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 30px;
  }
  .WorldMap {
    position: absolute;
    z-index: -1;
    opacity: 0.1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-5%);
    transform: translateX(-10%);
    width: auto;

    height: 100%;
  }
  .WorldMap img {
    width: 100%;
    height: auto;
  }
  .contact-info {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 768px) {
  .copyrightText {
    text-align: center;
  }
  .up-btn-area {
    display: none;
  }
  footer .title {
    font-size: 18px;
  }
  footer .text {
    font-size: 18px;
  }
  iframe {
    width: 100%;
    height: auto;
    border: none;
  }
  .flex-wrapF a {
    font-size: 18px;
  }
  .wrapper-grid3 .text {
    padding-bottom: 0;
  }
  .footer-bottom .wrapper-text {
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (max-width: 430px) {
  footer .title {
    font-size: 16px;
  }
  footer .text {
    font-size: 16px;
    padding-bottom: 30px;
  }
  .flex-wrapF a {
    font-size: 16px;
  }
  .wrapper-grid3 {
    margin-bottom: 0;
  }
}
