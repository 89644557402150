.about .container {
  overflow: visible;
}
.about h1 {
  font-size: clamp(26px, 3.53vw, 60px);
  font-weight: 400;
  color: var(--darkgreen);
  margin-bottom: 30px;
  line-height: 1.4;
}
.vzyn-field {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  position: relative;
  gap: 250px;
}
.vm-field {
  position: relative;
  z-index: 4;
}
.vyznSplit {
  content: "";
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  width: 2px;
  top: 0;
  left: 49%;

  background-color: hsla(0, 0%, 71%, 0.337);
  --tw-translate-x: -50%;
  margin-left: 15px;
  margin-right: 15px;
}
.vizyon-group {
  overflow: hidden;
  position: relative;
}
.vm-background {
  position: relative;
  background: rgba(249, 250, 252, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding-bottom: 1px;
  z-index: 9;
}

.vm-background .sekiller {
  z-index: 0;
  position: absolute;
  top: 70px;
  right: 0px;
  background-color: darkorchid;
  border-radius: 50%;
  filter: blur(35px) opacity(0.4);
  height: 100px;
  width: 100px;
  animation: sekil2 infinite alternate 15s;
}
@keyframes sekil2 {
  0% {
    top: 300px;
    right: 250px;
  }
}

@media screen and (max-width: 786px) {
  .vzyn-field {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .vyznSplit {
    display: none;
  }
}
