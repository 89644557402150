.home{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(0,0,1,.18),rgba(64, 64, 64, 0.549)),url('../Assets/lojistikHeader.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
   
    
  }
  .homeText{
    position: absolute;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-wrap: wrap;
    color: white;
    max-width: 60ch;
    transform: translateY(-50%);
    padding-left: 30px;
    padding-right: 30px;
   
    
  }
  .homeText .headerbaslik{
    font-weight: 500;
    margin-bottom: 30px;
   
  }
  .sloganText{
   color:hsla(0,0%,100%,.85)!important ;
   font-size: 18px;
   font-weight: 200;
   margin-bottom: 30px;
   
  }
  .backdrop-blur{
    filter: blur(5px);
  }
  .btn{

    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    cursor: pointer;
    /* overflow: hidden; */
    position: relative;
    white-space: nowrap;
    z-index: 9;
    backdrop-filter:blur(3px);
  }

  .before\:bg-primary-900:before{
    --tw-bg-opacity: 1;
    background-color: rgb(6 60 102/var(1));
    content: " ";
  }
  

@media screen and (max-width:600px) {
  .home .container {
    
    margin-inline: initial;
  }
  .sloganText{
    font-size: 15px;
  }
 }
 .btn--2:after{content:"";position:absolute;-webkit-transition-duration:inherit;-moz-transition-duration:inherit;transition-duration:inherit;-webkit-transition-property:inherit;-moz-transition-property:inherit;transition-property:inherit;-webkit-transition-timing-function:inherit;-moz-transition-timing-function:inherit;transition-timing-function:inherit;z-index:-2;}
 .btn{border:0;-webkit-box-shadow:none;box-shadow:none;color:#fff;cursor:pointer;overflow:hidden;position:relative;white-space:nowrap;z-index:9}
 .btn,.btn--2 .text{-webkit-transition-duration:.6s;-moz-transition-duration:.6s;transition-duration:.6s;-webkit-transition-property:all;-moz-transition-property:all;transition-property:all;-webkit-transition-timing-function:cubic-bezier(.77,0,.175,1);-moz-transition-timing-function:cubic-bezier(.77,0,.175,1);transition-timing-function:cubic-bezier(.77,0,.175,1)}
 .btn--2 .text{color:transparent;text-shadow:-50px 0 0 transparent,0 0 0 #fff}
 .btn.blue{border:0;-webkit-box-shadow:none;box-shadow:none;color:#043053;cursor:pointer;overflow:hidden;position:relative;white-space:nowrap;z-index:9}
 .btn--2.blue .text,.btn.blue{-webkit-transition-duration:.6s;-moz-transition-duration:.6s;transition-duration:.6s;-webkit-transition-property:all;-moz-transition-property:all;transition-property:all;-webkit-transition-timing-function:cubic-bezier(.77,0,.175,1);-moz-transition-timing-function:cubic-bezier(.77,0,.175,1);transition-timing-function:cubic-bezier(.77,0,.175,1)}
 .btn--2.blue .text{color:transparent;text-shadow:-50px 0 0 transparent,0 0 0 #043053}
 .btn--2:hover .text{text-shadow:0 0 0 #fff,50px 0 0 transparent}
 .btn--2.blue:hover .text{text-shadow:0 0 0 #043053,50px 0 0 transparent}
 .btn--2 .icon-animate{color:transparent;text-shadow:-50px 0 0 transparent,0 0 0 #fff;-webkit-transition-duration:.6s;-moz-transition-duration:.6s;transition-duration:.6s;-webkit-transition-property:all;-moz-transition-property:all;transition-property:all;-webkit-transition-timing-function:cubic-bezier(.77,0,.175,1);-moz-transition-timing-function:cubic-bezier(.77,0,.175,1);transition-timing-function:cubic-bezier(.77,0,.175,1)}
 .btn--2:hover .icon-animate{text-shadow:0 0 0 #fff,50px 0 0 transparent}
 .btn--2:after{
  content: " ";
  width: 0%;
  height: 100%;
  background: var(--darkgreen);
  position: absolute;
  transition: all 0.4s ease-in-out;
  left: 0;
  z-index: -1;
 }
 
 .btn--2:hover::after {
  right: auto;
  width: 100%;
 }

 /* scroll button */
 #scroll-down-animation {
  position: absolute;
  bottom:2%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}


.mouse {
  margin: 0 auto;
  display: block;
  border-radius: 50px;
  border: 1px solid #fff;
  height: 35px;
  width: 23px;
  position: relative;
}

.move {
  position: absolute;
  background-color: #fff;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  animation: move 2s linear infinite;
}

@keyframes move {
  0% {
    transform: translate(-50%,10px);
    opacity: 0;
  }
  50% {
    transform: translate(-50%,20px);
    opacity: 1;
  }
  100% {
    transform: translate(-50%,25px);
    opacity: 0;
  }
}
@media screen and (max-width:431px) {
  .homeText{
    padding-left: 5px;
    padding-right: 73px;
  }
  .homeText .headerbaslik{
    margin-bottom: 61px;
  }
  .flex-wrap a{
    font-size: 15px;
    
  }
  .height-65{
    height: 60px;
  }
}
 