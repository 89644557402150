.bizKimiz {
  position: relative;
  overflow: visible;
}
.bizKimiz .container {
  overflow: visible;
}
.bizKimiz .tanitim {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}
.bizKimiz h1 {
  font-size: clamp(26px, 3.53vw, 60px);
  font-weight: 400;
  color: var(--darkgreen);
  margin-bottom: 30px;
  line-height: 1.4;
}

.bannerLogo {
  position: absolute;
  bottom: -130px;
  left: -40px;
  opacity: 0.2;

  z-index: -1;
}
.truckBanner {
  display: flex;
  align-items: center;
  right: 0;
}
.bizKimiz .sekiller {
  position: absolute;
  top: 70px;
  background-color: darkorchid;
  border-radius: 50%;
  filter: blur(30px) opacity(0.4);
  height: 100px;
  width: 100px;
  animation: sekil infinite alternate 3s;
}
@keyframes sekil {
  0% {
    top: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .bizKimiz .tanitim {
    grid-template-columns: 1fr;
  }
}
